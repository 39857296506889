import Swiper from 'swiper';
import {Autoplay, Pagination} from 'swiper/modules';
Swiper.use([Autoplay, Pagination]);

const testimonialSwiper = new Swiper('.testimonialSwiper', {
  loop: true,
  initialSlide: 2,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  breakpoints: {
    1200: {
      slidesPerView: 1.8,
      spaceBetween: 150,
      centeredSlides: true,
    },
    700: {
      slidesPerView: 1.8,
      spaceBetween: 80,
      centeredSlides: true,
    },
    250: {
      slidesPerView: 1.3,
      centeredSlides: false,
      spaceBetween: 40,
    },
  },
});

const cardsSwiper = new Swiper('.cardsSwiper', {
  loop: true,
  slidesPerView: 5,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  breakpoints: {
    1200: {
      slidesPerView: 5,
      spaceBetween: 10,
    },
    800: {
      slidesPerView: 3.5,
      spaceBetween: 10,
    },
    400: {
      slidesPerView: 2.5,
      spaceBetween: 10,
    },
    300: {
      slidesPerView: 1.5,
      centeredSlides: false,
      spaceBetween: 10,
    },
  },
});
